import { Controller } from "@hotwired/stimulus"
import Cookies from 'js-cookie'
import Isotope from "isotope-layout";

export default class extends Controller {

    static targets = ['grid','search','resetWidget','resetsearch','resettags','filterWidget','openFilter','closeFilter','tags','tagTemplate'];

    static values = {
        init: Boolean,
        active: Boolean,
        timeout: Number,
        type: String,
        widgetopen: Boolean,
        tagsactive: Boolean,
        breakpoint: String,
        lastBreakpoint: Number,
        reinitResize: Boolean
    }

    connect() {

        this.resize();
        this.start();


    }

    start() {

        if (this.initValue) return;
        this.initValue = true;

        this.widgetopenValue = false;

        this.reinitResizeValue = false

        if(this.tagsactiveValue) {
            this.tagsTarget.dataset.tagsTemplate = this.tagTemplateTarget.innerHTML
            this.tagTemplateTarget.remove()
        }



        const iso = new Isotope( this.gridTarget, {

            itemSelector: '.grid-item',
            masonry: {

            }
        });



    }

    resize() {
        let width = window.innerWidth;
        let breakpoints = this.breakpointValue.split(",")
        let griditems = this.gridTarget.querySelectorAll(".grid-item")
        let current_breakpoint = 2

        if(width<744) {
            // Mobile
            current_breakpoint = 2;
        } else {
            if(width<1128) {
                // M
                current_breakpoint = 1;
            } else {
                // LG
                current_breakpoint = 0;
            }
        }



        if((this.lastBreakpointValue == current_breakpoint && this.initValue) && !this.reinitResizeValue ) {
            return
        }

        this.reinitResizeValue = false


        this.lastBreakpointValue = current_breakpoint

        let maxgriditems = 4
        if(griditems.length<4) {
            maxgriditems = griditems.length
        }

        for (var j = 0; j < maxgriditems; j++) {
            for(var p=1; p<7; p++) {
                griditems[j].classList.remove("p" + p)
            }
        }

        maxgriditems = breakpoints[current_breakpoint]
        if(griditems.length<breakpoints[current_breakpoint]) {
            maxgriditems = griditems.length
        }

        if(!this.widgetopenValue) {
            for (var j = 0; j < maxgriditems; j++) {
                const p = this.getRandomInt(5) + 1
                griditems[j].classList.add("p" + p)
            }
        }

        if (this.initValue) {

            griditems[0].addEventListener('transitionend', () => {
                let iso = Isotope.data(this.gridTarget);
                iso.layout()
            }, {once: true});

        } else {
            setTimeout(() => {
                let maxgriditems = 4
                if(griditems.length<4) {
                    maxgriditems = griditems.length
                }
                for (var j = 0; j < maxgriditems; j++) {
                    const p = this.getRandomInt(5) + 1
                    griditems[j].classList.add("grid-item-transition")
                }

            }, 100)
        }

    }

    getRandomInt(max) {
        let inv = 1

        return inv*Math.floor(Math.random() * max);
    }

    clearfilter(event) {
        const id = event.target.closest("li").dataset.id
        this.tagsTarget.querySelector('.id-'+id).remove()
        this.filterWidgetTarget.querySelector('.filter-'+id).classList.remove("active")

        this.search()
    }

    clearallfilter() {
        const allTags = this.tagsTarget.querySelectorAll("li")
        for (const tag of allTags) {
            const id = tag.dataset.id
            this.filterWidgetTarget.querySelector('.filter-'+id).classList.remove("active")
            tag.remove()

        }

        this.search()
    }

    filter(event) {

        event.target.classList.toggle("active")

        const id = event.target.getAttribute("data-id")
        const section = event.target.getAttribute("data-section")

        if (event.target.classList.contains('active')) {

            const tagsTemplate = this.tagsTarget.dataset.tagsTemplate

            const newTag = document.createElement("li")
            newTag.innerHTML = tagsTemplate
            newTag.classList.add('id-'+id)

            newTag.querySelector(".label").innerHTML = event.target.innerText
            newTag.dataset.id = id
            newTag.dataset.section = section


            this.tagsTarget.append(newTag)

        } else {

            this.tagsTarget.querySelector('.id-'+id).remove()

        }

        this.search()


    }

    search() {

        clearTimeout(this.timeoutValue);

        let iso = Isotope.data( this.gridTarget );

        const filtertags = []
        const filterentities = {}
        const filterentitiesFinal = []


        if(this.tagsactiveValue) {


            const allTags = this.tagsTarget.querySelectorAll("li")
            for (const tag of allTags) {
                const id = tag.dataset.id
                const section = tag.dataset.section

                if(!(section in filterentities)) {
                    filterentities[section] = []
                }
                filterentities[section].push(id)
                filtertags.push(id)

            }

            for (const key in filterentities) {
                filterentitiesFinal.push(
                    '{ section: "'+key+'", id:['+filterentities[key].join(",")+'] }'
                )
            }


        }

        if(this.searchTarget.value=="" && filtertags.length==0) {

            this.resetWidgetTarget.classList.add("hidden")

        } else {

            this.resetWidgetTarget.classList.remove("hidden")

            if(this.searchTarget.value!="") {
                this.resetsearchTarget.classList.remove("hidden")
            } else {
                this.resetsearchTarget.classList.add("hidden")
            }
            if(this.tagsactiveValue) {
                if (filtertags.length > 0) {

                    this.resettagsTarget.classList.remove("hidden")
                } else {
                    this.resettagsTarget.classList.add("hidden")
                }
            }
        }


        this.timeoutValue = setTimeout(() => {
            if(this.searchTarget.value=="" && filterentities.length==0) {


                iso.arrange({
                    filter: '*'
                })
                return
            }




            const resetsearchtarget_label = this.resetsearchTarget.querySelector(".label");
            resetsearchtarget_label.innerHTML = this.searchTarget.value




            let url = "/suche/filter-search?type="+this.typeValue+"&s="+this.searchTarget.value+"&e="+filterentitiesFinal.join(",")

            let result = this.resultTarget

            fetch(url).then(function (response) {
                // The API call was successful!
                return response.json();
            }).then(function (data) {

                let filterclasses = [];

                for (const el of data.data.entries) {
                    filterclasses.push(".item-"+el.id)
                }

                if(filterclasses.length) {

                    iso.arrange({
                        filter: filterclasses.join(",")
                    })

                } else {
                    iso.arrange({
                        filter: ".no-result"
                    })
                }




            }).catch(function (err) {
                // There was an error
                console.warn('Something went wrong.', err);
            });


        },200)

    }

    resetsearch() {
        this.searchTarget.value=""
        this.search()
    }

    toggleWidget() {

        this.widgetopenValue = !this.widgetopenValue

        if(this.widgetopenValue) {
            this.filterWidgetTarget.classList.remove("closed")
            this.openFilterTarget.classList.add("hidden")
            this.closeFilterTarget.classList.remove("hidden")

        } else {
            this.filterWidgetTarget.classList.add("closed")
            this.openFilterTarget.classList.remove("hidden")
            this.closeFilterTarget.classList.add("hidden")
        }

        this.reinitResizeValue = true

        setTimeout(() => {
            this.resize()
        }, 100)

    }

}