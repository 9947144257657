import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    static targets = ['close','cornerClose'];

    static values = {
        init: Boolean,
        active: Boolean,
    }

    connect() {



    }

    show() {


        setTimeout(() => {
            this.cornerCloseTarget.classList.remove("-translate-y-full");
            this.cornerCloseTarget.classList.remove("translate-x-full");
            this.cornerCloseTarget.addEventListener('transitionend', () => {
                this.closeTarget.classList.remove("opacity-0");
                this.cornerCloseTarget.classList.remove("transition");
            }, {once: true});
        }, 200)



    }

    close(event) {

        if(!document.querySelectorAll(".main-overlay").length) {
            return;
        }


        event.preventDefault();

        const level = (document.querySelectorAll(".main-overlay").length);

        const trigger3 = new CustomEvent("trigger-startover");
        window.dispatchEvent(trigger3);

        if(level==2) {
            const trigger2 = new CustomEvent("trigger-closelayer2");
            window.dispatchEvent(trigger2);
            history.pushState({layer:true}, "", document.body.dataset.originalUrl2);
        } else {
            const trigger2 = new CustomEvent("trigger-closelayer1");
            window.dispatchEvent(trigger2);
            history.pushState({}, "", document.body.dataset.originalUrl);

            const scrollY = document.body.style.top;
            document.body.style.position = '';
            document.body.style.top = '';
            document.body.style.overflowY = ''
            document.body.style.width = ''

            window.scrollTo(0, parseInt(scrollY || '0') * -1);

            document.body.classList.remove('overlay-open');

        }

        this.cornerCloseTarget.closest(".main-overlay").remove();




    }



}