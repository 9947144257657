import { Controller } from "@hotwired/stimulus"
import Cookies from 'js-cookie'
import Isotope from "isotope-layout";

export default class extends Controller {

    static targets = ['grid'];

    static values = {
        init: Boolean,
        active: Boolean,
        breakpoint: String,
        lastBreakpoint: Number,
        reinitResize: Boolean
    }

    connect() {

        this.resize();
        this.start();

    }

    start() {

        if (this.initValue) return;
        this.initValue = true;

        this.reinitResizeValue = false

        var iso = new Isotope( this.gridTarget, {
            // options...
            itemSelector: '.grid-item',
            masonry: {

            }
        });

    }

    resize() {
        let width = window.innerWidth;
        let breakpoints = this.breakpointValue.split(",")
        let griditems = this.gridTarget.querySelectorAll(".grid-item")
        let current_breakpoint = 2

        if(width<744) {
            // Mobile
            current_breakpoint = 2;
        } else {
            if(width<1128) {
                // M
                current_breakpoint = 1;
            } else {
                // LG
                current_breakpoint = 0;
            }
        }



        if((this.lastBreakpointValue == current_breakpoint && this.initValue) && !this.reinitResizeValue ) {
            return
        }

        this.reinitResizeValue = false


        this.lastBreakpointValue = current_breakpoint

        let maxgriditems = 4
        if(griditems.length<4) {
            maxgriditems = griditems.length
        }

        for (var j = 0; j < maxgriditems; j++) {
            for(var p=1; p<7; p++) {
                griditems[j].classList.remove("p" + p)
            }
        }

        maxgriditems = breakpoints[current_breakpoint]
        if(griditems.length<breakpoints[current_breakpoint]) {
            maxgriditems = griditems.length
        }

        if(!this.widgetopenValue) {
            for (var j = 0; j < maxgriditems; j++) {
                const p = this.getRandomInt(5) + 1
                griditems[j].classList.add("p" + p)
            }
        }

        if (this.initValue) {

            griditems[0].addEventListener('transitionend', () => {
                let iso = Isotope.data(this.gridTarget);
                iso.layout()
            }, {once: true});

        } else {
            setTimeout(() => {
                let maxgriditems = 4
                if(griditems.length<4) {
                    maxgriditems = griditems.length
                }
                for (var j = 0; j < maxgriditems; j++) {
                    const p = this.getRandomInt(5) + 1
                    griditems[j].classList.add("grid-item-transition")
                }

            }, 100)
        }

    }

    getRandomInt(max) {
        let inv = 1

        return inv*Math.floor(Math.random() * max);
    }



}