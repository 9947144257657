// src/controllers/main_navigation_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    static targets = ['mobileNavigationOuter','mobileNavigationInner','mobileNavigationRootLevel','whiteBg','logo','toggler','togglerBurger','togglerClose','togglerBack'];

    static values = {
        init: Boolean,
        active: Boolean,
        currentroute: Number
    }

    connect () {
        this.initialized = true

        this.start();


    }

    start() {

        if (this.initValue) return;
        this.initValue = true;
        this.currentrouteValue = 0;

        const folderLinks = this.mobileNavigationOuterTarget.querySelectorAll('.folder');

        for (const folderLink of folderLinks) {

            this.boundFolderLink = this.openroute.bind(this)
            folderLink.addEventListener("click", this.boundFolderLink);

        }

    }



    toggleMenu() {


        if(this.currentrouteValue>0) {

            this.closeroute();

        } else {

            this.activeValue = !this.activeValue

            if (this.activeValue) {
                this.showMenu()
            } else {
                this.hideMenu()
            }
        }



    }

    showMenu() {

        document.body.style.top = `-${window.scrollY}px`
        document.body.style.position = 'fixed'
        document.body.style.overflowY = 'scroll'
        document.body.style.width = '100%'

        document.body.classList.add('menu-open');



        const trigger = new CustomEvent("trigger-flyOutMobileNavigation");
        window.dispatchEvent(trigger);


        this.mobileNavigationOuterTarget.classList.remove('opacity-0');

        this.logoTarget.classList.remove('z-[100]');

        this.togglerCloseTarget.classList.remove('hidden');
        this.togglerBurgerTarget.classList.add('hidden');
        //this.togglerTarget.classList.remove('absolute');
        //this.togglerTarget.classList.add('fixed');

        setTimeout(() => {
            this.whiteBgTarget.classList.remove('opacity-0');
            this.mobileNavigationOuterTarget.classList.remove('-translate-x-full');
            this.whiteBgTarget.classList.remove('-translate-x-full');
        },50);

    }

    hideMenu() {

        if (this.initialized) {

            const scrollY = document.body.style.top;
            document.body.style.position = '';
            document.body.style.top = '';
            document.body.style.overflowY = ''
            document.body.style.width = ''

            window.scrollTo(0, parseInt(scrollY || '0') * -1);

            document.body.classList.remove('menu-open');

            const trigger = new CustomEvent("trigger-flyOutMobileNavigation");
            window.dispatchEvent(trigger);

            this.mobileNavigationOuterTarget.classList.add('-translate-x-full');

            this.whiteBgTarget.classList.add('-translate-x-full');

            this.togglerCloseTarget.classList.add('hidden');
            this.togglerBurgerTarget.classList.remove('hidden');

            //this.togglerTarget.classList.add('absolute');
            //this.togglerTarget.classList.remove('fixed');


            this.mobileNavigationOuterTarget.addEventListener('transitionend', () => {
                this.logoTarget.classList.add('z-[100]');
                this.mobileNavigationOuterTarget.classList.add('opacity-0');
                this.whiteBgTarget.classList.add('opacity-0');
            }, {once: true});

        }
    }

    openroute(event) {

        this.mobileNavigationInnerTarget.classList.add('-translate-x-full');
        this.mobileNavigationRootLevelTarget.classList.remove('overflow-y-auto');

        const entryId = event.target.getAttribute('data-id');
        this.currentrouteValue = entryId;

        this.mobileNavigationInnerTarget.querySelector('.folder-'+entryId).classList.remove('overflow-y-hidden');
        this.mobileNavigationInnerTarget.querySelector('.folder-'+entryId).classList.add('overflow-y-auto');
        this.mobileNavigationInnerTarget.querySelector('.folder-'+entryId).classList.remove('hidden');

        this.togglerBackTarget.classList.remove('hidden');
        this.togglerCloseTarget.classList.add('hidden');

        const trigger = new CustomEvent("trigger-flyOutMobileNavigationExpand");
        window.dispatchEvent(trigger);

    }

    closeroute() {
        this.togglerBackTarget.classList.add('hidden');
        this.togglerCloseTarget.classList.remove('hidden');

        this.mobileNavigationInnerTarget.querySelector('.folder-'+this.currentrouteValue).classList.remove('overflow-y-hidden');
        this.mobileNavigationInnerTarget.querySelector('.folder-'+this.currentrouteValue).classList.add('overflow-y-auto');

        const trigger = new CustomEvent("trigger-flyOutMobileNavigationExpand");
        window.dispatchEvent(trigger);

        this.mobileNavigationInnerTarget.classList.remove('-translate-x-full');
        this.mobileNavigationRootLevelTarget.classList.add('overflow-y-auto');


        this.mobileNavigationInnerTarget.addEventListener('transitionend', () => {
            this.mobileNavigationInnerTarget.querySelector('.folder-'+this.currentrouteValue).classList.add('hidden');
            this.currentrouteValue = 0;
        }, {once: true});

    }
}