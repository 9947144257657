// src/controllers/main_navigation_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    static targets = ['cornerMobileNavigation','cornerDesktopNavigation', 'backgrounds'];

    static values = {
        init: Boolean,
        mobileFlyout: Boolean,
        mobileFlyoutExpand: Boolean,
        scrolltimeout: Number,
        scroll: Number
    }

    connect () {
        this.initialized = true
        this.mobileFlyoutValue = false;
        this.mobileFlyoutExpandValue = false;
        this.desktopNavigationValue = false;
        this.start();
        
    }

    start() {

        if (this.initValue) return;
        this.initValue = true;
        this.scrollValue = 1

        this.startLayers()

    }

    startLayers() {

        if(document.querySelector("body").classList.contains("start")) return;

        const colors = ["#07b4ed","#e92c93","#fff106"]

        const backgrounds_active = this.backgroundsTarget.querySelectorAll(".background")
        for (const background of backgrounds_active) {
            background.classList.remove("init")
            background.classList.remove("active")
            background.classList.add(background.getAttribute("data-position"))
            background.classList.add("hidden")
            background.querySelector("svg").removeAttribute("style")
        }

        var r = document.querySelector(':root');

        for(var i=1; i<10; i++) {
            r.style.setProperty('--shape-color-'+i, colors[this.getRandomInt(3)]);
        }

        const backgrounds = this.backgroundsTarget.querySelectorAll(".background")

        let maxBackgrounds = backgrounds.length
        let width = window.innerWidth;
        if(width<1128 || document.querySelectorAll(".main-overlay").length>0) {
            maxBackgrounds = maxBackgrounds-2

        } else {

        }

        let bgId = this.getRandomInt(maxBackgrounds)
        const background = backgrounds[bgId]

        background.classList.add("active")
        background.classList.remove("hidden")

        setTimeout(() => {
            background.classList.add("init")
            background.classList.remove(background.getAttribute("data-position"))
        },500)
    }

    resize() {
        return
        let height = window.innerHeight;
        let cornerDesktopNavigationHeight = this.cornerDesktopNavigationTarget.offsetHeight
        let cornerDesktopNavigationTop = this.cornerDesktopNavigationTarget.offsetTop

        if(cornerDesktopNavigationHeight+cornerDesktopNavigationTop<height) {
            this.cornerDesktopNavigationTarget.classList.remove("top-2/3")
            this.cornerDesktopNavigationTarget.classList.add("bottom-0")
        } else {
            this.cornerDesktopNavigationTarget.classList.add("top-2/3")
            this.cornerDesktopNavigationTarget.classList.remove("bottom-0")
        }

    }

    startover() {

        this.startLayers()
    }

    openlayer1() {
        this.backgroundsTarget.classList.add("!z-[100]")
    }

    openlayer2() {
        this.backgroundsTarget.classList.add("!z-[155]")
    }

    closelayer1() {
        this.backgroundsTarget.classList.remove("!z-[100]")
    }

    closelayer2() {
        this.backgroundsTarget.classList.remove("!z-[155]")
    }

    getRandomInt(max) {
        let inv = 1

        return inv*Math.floor(Math.random() * max);
    }

    onScroll() {
        if(document.querySelector("body").classList.contains("start") || document.querySelector("body").classList.contains("overlay-open")) return;


        const background = this.backgroundsTarget.querySelector(".active")

        let scroll = window.scrollY;
        let maxScroll = 0.01 * (100/(document.querySelector("body").scrollHeight - window.innerHeight))


        let scale = 1 + (maxScroll*scroll*0.3)

        if(scale>this.scrollValue) {
            this.scrollValue = scale
            background.querySelector("svg").setAttribute("style","transform: scale("+scale+")")
        }








    }
    
    flyOutMobileNavigation() {

        this.mobileFlyoutValue = !this.mobileFlyoutValue;
        
        if(this.mobileFlyoutValue) {

            //this.cornerMobileNavigationTarget.classList.add('z-[20]');
            setTimeout(() => {
                this.cornerMobileNavigationTarget.classList.add('transition-smooth');
                this.cornerMobileNavigationTarget.classList.add('transition-all');
                this.cornerMobileNavigationTarget.classList.add('duration-1000');
                this.cornerMobileNavigationTarget.classList.remove('-translate-y-main-nav-bg');
            }, 50);

        } else {
            this.cornerMobileNavigationTarget.classList.add('-translate-y-main-nav-bg');
        }



    }

    flyOutMobileNavigationExpand() {
        this.mobileFlyoutExpandValue = !this.mobileFlyoutExpandValue;

        if(this.mobileFlyoutExpandValue) {
            this.cornerMobileNavigationTarget.classList.add('scale-[3]');
        } else {
            this.cornerMobileNavigationTarget.classList.remove('scale-[3]');
        }
    }

    showDesktopNavigation() {

        this.desktopNavigationValue = !this.desktopNavigationValue;

        this.cornerDesktopNavigationTarget.classList.remove('opacity-0');
        this.cornerDesktopNavigationTarget.classList.remove('translate-y-full');
        this.cornerDesktopNavigationTarget.addEventListener('transitionend', () => {
            this.cornerDesktopNavigationTarget.classList.add('duration-0');
        }, {once: true});

    }

    hideDesktopNavigation() {

        this.desktopNavigationValue = !this.desktopNavigationValue;

        this.cornerDesktopNavigationTarget.classList.add('opacity-0');
        this.cornerDesktopNavigationTarget.classList.add('translate-y-full');
        this.cornerDesktopNavigationTarget.classList.remove('duration-0');


    }
    
}