// src/controllers/main_navigation_controller.js
import { Controller } from "@hotwired/stimulus"
import Flickity from 'flickity'

export default class extends Controller {

    static targets = ['gallery'];

    static values = {
    }

    initialize() {

        var flkty = new Flickity(  this.galleryTarget, {
            // options
            cellAlign: 'left',
            contain: true,
            arrowShape: 'M72.512,99.525c-1.155,0-2.304-0.44-3.183-1.319L24.305,53.182c-0.844-0.844-1.319-1.99-1.319-3.183\n' +
                '\t\tc0-1.193,0.475-2.339,1.319-3.183L69.329,1.794c1.759-1.759,4.608-1.759,6.366,0s1.759,4.608,0,6.366L33.854,49.999L75.695,91.84\n' +
                '\t\tc1.759,1.759,1.759,4.608,0,6.366C74.816,99.086,73.667,99.525,72.512,99.525z'
        });

    }



}