import { Controller } from "@hotwired/stimulus"
import Cookies from 'js-cookie'
import marquee from 'vanilla-marquee'

export default class extends Controller {

    static targets = ['container','list'];

    static values = {
        active: Boolean,
    }

    connect() {

        this.start();

    }

    start() {

        if (this.initValue) return;
        this.initValue = true;



        let tickerelements = []
        let tickerelements_splitted = []

        const org_tickerelements = this.listTarget.querySelectorAll("li")
        for (const tickerelement of org_tickerelements) {
            tickerelements.push(tickerelement)
        }

        tickerelements = this.shuffle(tickerelements)



        const block = Math.round(tickerelements.length/4);

        let direction = "left"

        for (var i = 0; i < 4; i++) {
            const start_slice = i*block;
            const end_slice = (i*block)+block;
            let tsliced = tickerelements.slice(start_slice,end_slice);

            tickerelements_splitted.push(tsliced)

            const mq = this.listTarget.cloneNode(true);
            const mqul = mq.querySelector("ul")
            mqul.innerHTML = ""
            mqul.classList.remove("hidden")
            for (var j = 0; j < 8; j++) {
                for (const tickerelement of tsliced) {
                    var clone = tickerelement.cloneNode(true);
                    clone.setAttribute("style","padding: 0 "+(0.3+(this.getRandomInt(8)*0.3))+"em")
                    mqul.append(clone);
                }
            }

            this.containerTarget.append(mq)

            const speed = 20+this.getRandomInt(50);


            new marquee( mq, {
                speed: speed,
                delayBeforeStart: 0,
                pauseOnHover: true,
                startVisible: true,
                duplicated: true,
                direction: direction
            });

            if(direction=="left") {
                direction="right"
            } else {
                direction="left"
            }


        }






    }

    getRandomInt(max) {
        let inv = 1

        return inv*Math.floor(Math.random() * max);
    }

    shuffle(array) {

        var currentIndex = array.length;
        var temporaryValue, randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {
            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }

        return array;

    };



}