// src/controllers/main_navigation_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    static targets = ['nav','whiteBg','whiteBgUnderNav','navOuter', 'desktopNavigationOuter'];

    static values = {
        init: Boolean,
        active: Boolean,
        timeout: Number
    }

    connect () {
        this.initialized = true

        this.start();
    }

    start() {

        if (this.initValue) return;
        this.initValue = true;

        setTimeout(() => {
            this.navOuterTarget.classList.add("navinitanimation-init")
            this.navOuterTarget.classList.add("animate")
        },50)



    }




    mouseOver(event) {


        if(document.body.classList.contains("desktop-menu-open")) {
            this.mouseOut()
            return
        }

        clearTimeout(this.timeoutValue);

        let entryId = 0

        if(event.target.parentNode.classList.contains("folder")) {
            entryId = event.target.parentNode.getAttribute('data-id');
        } else {
            entryId = event.target.getAttribute('data-id');
        }


        //let entryId = event.target.parentNode.getAttribute('data-id');

        this.whiteBgUnderNavTarget.classList.remove("opacity-0");
        this.whiteBgTarget.classList.remove("-translate-y-full");

        this.desktopNavigationOuterTarget.querySelector('.folder-'+entryId).classList.remove("hidden");
        this.desktopNavigationOuterTarget.querySelector('.folder-'+entryId).classList.add("folder-active");

        this.navTarget.querySelector('.folder-entry-'+entryId).classList.add("folder-active");
        setTimeout(() => {
            this.desktopNavigationOuterTarget.querySelector('.folder-'+entryId).classList.remove("opacity-0");
            this.desktopNavigationOuterTarget.querySelector('.folder-'+entryId).classList.remove("-translate-y-10");
        },50);


        document.body.style.top = `-${window.scrollY}px`
        document.body.style.position = 'fixed'
        document.body.style.overflowY = 'scroll'
        document.body.style.width = '100%'

        document.body.classList.add("desktop-menu-open")

        const trigger = new CustomEvent("trigger-showDesktopNavigation");
        window.dispatchEvent(trigger);

    }

    mouseOutStop() {

        clearTimeout(this.timeoutValue);
    }

    mouseOut() {

        const folderActive = this.desktopNavigationOuterTarget.querySelector('.folder-active');



        if(folderActive) {



            this.timeoutValue = setTimeout(() => {



                document.body.classList.remove("desktop-menu-open")


                this.whiteBgUnderNavTarget.classList.add("opacity-0");
                this.whiteBgTarget.classList.add("-translate-y-full");


                folderActive.classList.add("hidden");
                folderActive.classList.add("opacity-0");
                folderActive.classList.add("-translate-y-10");
                folderActive.classList.remove('folder-active');

                this.navTarget.querySelector('.folder-active').classList.remove('folder-active');

                const trigger = new CustomEvent("trigger-hideDesktopNavigation");
                window.dispatchEvent(trigger);

                setTimeout(() => {
                    const scrollY = document.body.style.top;
                    document.body.style.position = '';
                    document.body.style.top = '';
                    document.body.style.overflowY = ''
                    document.body.style.width = ''

                    window.scrollTo(0, parseInt(scrollY || '0') * -1);
                }, 100);
            }, 200);

        }

    }


}