import { Controller } from "@hotwired/stimulus"
import Cookies from 'js-cookie'

export default class extends Controller {

    static targets = ['video','play','preview'];

    static values = {
        active: Boolean,
    }

    connect() {



    }

    playVideo() {

        this.playTarget.classList.add("hidden");
        this.previewTarget.classList.add("hidden");
        this.videoTarget.classList.remove("hidden");
        this.videoTarget.play();

    }





}