import { Controller } from "@hotwired/stimulus"
import Cookies from 'js-cookie'

export default class extends Controller {

    static targets = ['content','trigger','open','close'];

    static values = {
        active: Boolean,
    }

    connect() {



    }

    toggle() {

        this.activeValue = !this.activeValue


        if (this.activeValue) {

            this.contentTarget.classList.remove("closed");

            this.openTarget.classList.add("hidden");
            this.closeTarget.classList.remove("hidden");

            this.contentTarget.addEventListener('transitionend', () => {
                setTimeout( () => {

                    this.triggerTarget.parentNode.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                    });

                }, 10);
            }, {once: true});



        } else {
            this.contentTarget.classList.add("closed");
            this.openTarget.classList.remove("hidden");
            this.closeTarget.classList.add("hidden");
        }



    }





}