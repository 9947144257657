import { Controller } from "@hotwired/stimulus"
import Cookies from 'js-cookie'
import Isotope from "isotope-layout";

export default class extends Controller {

    static targets = ['search','reset','result'];

    static values = {
        init: Boolean,
        active: Boolean,
        timeout: Number,
        lastSearchLength: Number
    }

    connect() {

        this.lastSearchLengthValue = 0

    }



    search() {

        clearTimeout(this.timeoutValue);

        this.timeoutValue = setTimeout(() => {
            if(this.searchTarget.value=="") {

                this.resetTarget.classList.add("hidden")
                this.resultTarget.innerHTML = ""
                return
            }

            this.resetTarget.classList.remove("hidden")

            let url = "/suche/ajax?s="+this.searchTarget.value

            let result = this.resultTarget

            fetch(url).then((response) => {
                response.text().then((text) => {



                    if(this.lastSearchLengthValue != text.length) {

                        this.lastSearchLengthValue = text.length

                        result.innerHTML = text

                        let lazyImageObserver = new IntersectionObserver(function (entries, observer) {
                            entries.forEach(function (entry) {
                                if (entry.isIntersecting) {
                                    let lazyImage = entry.target;
                                    lazyImage.src = lazyImage.dataset.src;
                                    lazyImage.srcset = lazyImage.dataset.srcset;
                                    lazyImage.classList.remove("lazy-start");
                                    lazyImage.classList.add("lazyloaded");
                                    lazyImageObserver.unobserve(lazyImage);
                                }
                            });
                        });

                        let lazyImages = [].slice.call(document.querySelectorAll("img.lazy-start"));
                        lazyImages.forEach(function (lazyImage) {
                            lazyImageObserver.observe(lazyImage);
                        });

                        let aos_elements = document.querySelectorAll("[data-add-animation]")
                        for (const el of aos_elements) {
                            el.classList.add("init-animation")
                            el.classList.add("animate")
                        }
                    }

                });
            })
        },50)

    }

    reset() {
        this.searchTarget.value=""
        this.search()
    }



}