import { Controller } from "@hotwired/stimulus"
import Cookies from 'js-cookie'
import marquee from 'vanilla-marquee'

export default class extends Controller {

    static targets = ['container','list'];

    static values = {
        active: Boolean,
    }

    connect() {

        this.start();

    }

    start() {

        if (this.initValue) return;
        this.initValue = true;

        const tickerelements = this.listTarget.querySelectorAll("li")

        for (var i = 0; i < 5; i++) {

            for (const tickerelement of tickerelements) {

                var clone = tickerelement.cloneNode(true);

                this.listTarget.append(clone)

            };
        };

        new marquee( this.containerTarget, {
            speed: 50,
            delayBeforeStart: 0,
            pauseOnHover: true,
            startVisible: true,
            duplicated: true
        });


    }






}